<template>
  <div class="letters-table">
    <div v-show="isFetching" class="calm-spinner-page-placeholder spinner-container">
      <div class="calm-spinner" />
    </div>

    <table class="table unstriped">
      <thead>
        <tr>
          <th v-if="!readOnly" />
          <th v-if="!readOnly && courseAppContext" />
          <th>{{ $t('activerecord.attributes.message.title') }}</th>
          <th class="hidden-xs">
            {{ $t('activerecord.attributes.message.from') }}
          </th>
          <th v-if="courseAppContext && $can('manage_letter_tags', 'messages')">
            {{ $t('activerecord.attributes.message.letter_tag') }}
          </th>
          <th class="hidden-xs">
            {{ $t('activerecord.attributes.message.status') }}
            <a class="delivery-help" :href="statusHelpPath" target="_blank">
              <i class="fa fa-question-circle" title="Message Delivery Help" />
            </a>
          </th>
          <th class="date min-medium">
            {{ $t('activerecord.attributes.message.sent_at') }}
          </th>
        </tr>
      </thead>
      <tbody>
        <template v-for="letter in letters" :key="letter.id">
          <LettersTableRow
            v-model:activeLetterId="activeLetterId"
            :letter="letter"
            :read-only="readOnly"
            :course-app-context="courseAppContext"
            @toggle-important="toggleImportant"
            @update:letter="updateLetter"
          />
          <LettersTableExpandedMessage
            :letter="letter"
            :read-only="readOnly"
            :active-letter-tags="activeLetterTags"
            :user-roles="userRoles"
            :active-letter-id="activeLetterId"
            @update:letter="updateLetter"
            @letter-deleted="removeLetter"
          />
        </template>
      </tbody>
    </table>

    <p v-show="!isFetching && letters.length == 0" class="text-center">
      No Messages
    </p>
  </div>
</template>

<script>
import LettersTableRow from 'components/letters/LettersTableRow'
import LettersTableExpandedMessage from 'components/letters/LettersTableExpandedMessage'

export default {
  components: { LettersTableRow, LettersTableExpandedMessage },
  props: {
    contextId: { type: [String, Number], required: true },
    contextType: {
      type: [String],
      required: true,
      validator(value) {
        return ['CourseApplication', 'Event', 'Person'].indexOf(value) !== -1
      }
    },
    activeLetterTags: { type: Array, default: () => [] },
    userRoles: { type: Array, default: () => [] },
    readOnly: Boolean
  },
  data() {
    return {
      letters: [],
      activeLetterId: null,
      isFetching: false
    }
  },
  mounted() {
    if (gon.inbox_transmission_id) this.activeLetterId = gon.inbox_transmission_id
  },
  computed: {
    statusHelpPath() {
      return this.$routes.message_status_help_path()
    },
    fetchUrl() {
      switch (this.contextType) {
        case 'CourseApplication':
          return this.$routes.course_application_messages_path(this.contextId)
        case 'Event':
          return this.$routes.event_communication_path(this.contextId)
        case 'Person':
          return this.$routes.comm_transmissions_path({ person_id: this.contextId })
        default:
          return ''
      }
    },
    courseAppContext() {
      return this.contextType == 'CourseApplication'
    }
  },
  methods: {
    fetchLetters(openLastAdded = false) {
      if (this.isFetching) return
      this.isFetching = true
      $.getJSON(this.fetchUrl, (data) => {
        this.isFetching = false
        this.letters = data
        if (openLastAdded && this.letters.length > 0) {
          this.activeLetterId = this.letters[0].id
        }
      })
    },
    onShow() {
      if (this.letters.length == 0) this.fetchLetters()
    },
    removeLetter(letter) {
      this.letters = this.letters.filter((l) => l.id != letter.id)
    },
    updateLetter(newLetter) {
      const indexToUpdate = this.letters.findIndex((l) => l.id == newLetter.id)
      this.letters.splice(indexToUpdate, 1, newLetter)
    },
    toggleLetterTag(letterContext, type, tag) {
      $.ajax(letterContext.actions.resolve_tag.url, {
        type: 'PATCH',
        dataType: 'json',
        data: { type, letter_tag: tag },
        success: (data) => {
        // When we change on letter tag, it can influence other letters tags
        // (need to update pending true/false) so backend send us the list of the letters
        // who have been updated after letter_tag action
          data = data.updated_transmissions
          this.letters.forEach((letter) => {
            if (data[letter.id]) letter.letter_tags = data[letter.id]
          })
          const newActiveTags = this.letters.filter((l) => l.letter_tags.pending)
            .map((l) => l.letter_tags.activated)
          this.$root.updateActiveTags(newActiveTags)
        }
      })
    },
    toggleImportant(letterContext) {
      $.ajax(letterContext.actions.toggle_important.url, {
        type: 'PATCH',
        dataType: 'json',
        success: (data) => {
          letterContext.important = data.important
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.letters-table {
  position: relative;
  min-height: 100px;
}
.delivery-help {
  color: white;
  font-size: 1em;
  margin-left: 5px;
}
.spinner-container {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  padding-top: 50px;
  min-height: 100px;
  margin-top: 0;
  background-color: rgba(243, 243, 243, 0.5);
}
th.date {
  @media (min-width: 800px) {
    min-width: 100px;
  }
}
</style>
